<template>
  <div id="playbackManagement">
    <ul class="ul1">
      <li class="item">
        <div class="cover"></div>
        <div class="operation">
          <div class="title">钢琴快速入门讲座</div>
          <div class="courseinfo">
            直播老师：李老师
            <span style="margin: 0 5px">|</span>
            直播时间：2021-05-20 20:58
            <span style="margin: 0 5px">|</span>
            生成时间：2021-05-20 20:58
            <span style="margin: 0 5px">|</span>
            文件大小：183MB
            <span style="margin: 0 5px">|</span>
            视频时长：00:53:47
          </div>
          <div class="coursebtns">
            <div class="leftbnt">
              <el-button type="primary" size="mini">下载</el-button>
              <el-button size="mini" class="share ml20" @click="del">
                删除
              </el-button>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      label-position="left"
    >
      <el-form-item label="回放名称" :required="true">
        <el-input
          placeholder="请输入回放名称"
          style="width: 618px"
          v-model="ruleForm.name"
          maxlength="50"
          size="medium"
          show-word-limit
        ></el-input>
        <div class="tip mt10">
          提示：回放名称会作用到学员端，请注意命名规范；系统默认按直播时间命名
        </div>
      </el-form-item>
      <el-form-item label="展示到列表">
        <div slot="label" class="ml10">展示到列表</div>
        <el-switch v-model="ruleForm.value"></el-switch>
        <span class="tip" style="margin-left: 40px">
          提示：关闭后，学员端的【回放列表】不会显示该回放视频
        </span>
      </el-form-item>
      <el-form-item label="回放文件">
        <div slot="label" class="ml10">回放文件</div>
        <div class="huifang">
          <div class="name">5285890807063729509.m3u8</div>
          <div class="tip">大小：183MB | 时长：00:53:47</div>
          <div class="btn">
            <el-button size="medium" style="width: 114px">替换回放</el-button>
            <changePhotoimg
              pictureTitle="插入视频(单选)"
              :type="2"
              :uploadType="3"
              title="插入视频(单选)"
              :isshow="true"
              url="/User/editUserSimpleInfo"
              @complete="complete"
              class="changePhotoimg"
            ></changePhotoimg>
            <span class="tip ml20">
              提示：目前仅支持视频替换，暂不支持在线剪辑，可从本地上传或素材库选择视频替换
            </span>
          </div>
          <div class="hadselect" v-if="selectvedio.name">
            <div class="content">已选：{{ selectvedio.name }}</div>
            <div class="remove" @click="remove">移除文件</div>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="btns">
      <el-button class="mr10" size="medium" style="width: 86px">取消</el-button>
      <el-button type="primary" size="medium" style="width: 86px">
        保存
      </el-button>
    </div>
    <tip v-if="status" :list="list" :dialogstatus.sync="status"></tip>
  </div>
</template>

<script>
import tip from '../components/tip'
import changePhotoimg from '@cm/base/changePhotoimg'
export default {
  name: 'playbackManagement',

  components: {
    changePhotoimg,
    tip,
  },

  data() {
    return {
      list: {},

      status: false,

      selectvedio: {},

      ruleForm: {
        name: '',
        value: '',
        url: '',
      },
    }
  },
   
  methods: {
    //删除
    del() {
      this.list = {
        type: 2,
        contain: '是否确定删除该回放视频，删除后可在回收站中还原！',
      }
      //   叮豆不足，无法发起直播，请前往网校账户中充值叮豆！
      this.$nextTick(() => {
        this.status = true
      })
    },

    //移除
    remove() {
      this.selectvedio = {}
      this.ruleForm.url = ''
    },
    
    complete(val) {
      this.selectvedio = val
      this.ruleForm.url = val.url
    },
  },
}
</script>

<style lang="scss" scoped>
#playbackManagement {
  padding: 20px;
  background-color: #fff;
  .ul1 {
    margin-bottom: 40px;
    .item {
      display: flex;
      .cover {
        border-radius: 5px;
        width: 196px;
        height: 110px;
        background-color: grey;
        margin-right: 20px;
        position: relative;
      }
      .operation {
        width: calc(100% - 300px);
        .title {
          font-size: 16px;
          font-weight: bold;
          color: #363636;
          line-height: 16px;
        }
        .courseinfo {
          font-size: 13px;
          color: #666666;
          line-height: 18px;
          margin: 10px 0 20px;
          height: 36px;
        }
        .coursebtns {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .leftbnt {
            ::v-deep .el-button {
              width: 100px !important;
              border-radius: 15px !important;
              border: 1px solid #0aa29b;
            }
            .share {
              color: #0aa29b !important;
            }
          }
          .rightbtn {
            font-size: 13px;
            color: #0aa29b;
            line-height: 13px;

            .sp_name {
              padding: 0 5px;
              cursor: pointer;
            }
            ::v-deep .el-dropdown {
              font-size: 13px;
              color: #0aa29b;
            }
          }
        }
      }
    }
  }
  .tip {
    font-size: 12px;
    color: #666666;
    line-height: 12px;
  }
  .huifang {
    .name {
      font-size: 16px;
      color: #363636;
      line-height: 16px;
      margin-bottom: 10px;
    }
    .btn {
      position: relative;
      margin: 20px 0 10px;
      display: flex;
      align-items: center;
      .changePhotoimg {
        position: absolute;
        top: 0;
        width: 114px !important;
        height: 36px !important;
      }
    }
    .hadselect {
      width: 647px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .content {
        font-size: 16px;
        color: #363636;
        line-height: 16px;
      }
      .remove {
        padding: 10px;
        cursor: pointer;
        font-size: 16px;
        color: #ff3535;
        line-height: 16px;
      }
    }
  }
  .btns {
    margin-top: 50px;
    margin-left: 355px;
  }
}
</style>